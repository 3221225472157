<template>
  <div class="prodlist-area">
    <div v-if="items.length > 0" class="card-group">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="card-items"
        @click="toItemDetail(item)"
      >
        <div
          class="img"
          :style="'background-image: url(' + item.cover_image + ');'"
        >
          <div v-if="item.promote || item.saleoff" class="overlay">
            <div v-if="item.saleoff" class="promote-sale">
              <div class="box">
                <big>{{ item.saleoff }}</big>
              </div>
            </div>
            <div v-if="item.promote" class="promote-text">
              <img :src="item.promote" alt="" class="img-fluid">
            </div>
          </div>
        </div>
        <p class="name">
          {{ item.name }}
        </p>
        <!-- 金額原價=售價  顯示: 優惠價-->
        <p
          v-if="item.origin_price && item.origin_price <= item.min_price"
          class="price-2"
        >
          優惠價
        </p>
        <p v-else-if="item.origin_price" class="price-o">
          NT${{ item.origin_price | priceFormat }}
        </p>
        <!-- 金額為0 只有點數-->
        <p v-if="item.min_price < 1" class="price">
          <span v-show="item.min_price === 0 && item.point === 0"> 免費</span>
          <span v-show="item.point !== null && item.point > 0">
            {{ item.point | priceFormat }}點</span>
        </p>
        <p v-else class="price">
          <span class="sign">NT$</span>{{ item.min_price | priceFormat }}
          <span v-show="item.point !== null && item.point > 0" class="price-1">
            + {{ item.point | priceFormat }}點</span>
        </p>
      </div>
    </div>
    <div v-else>
      <p class="no_data">
        {{ status }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selectedCatId: {
      type: [String, Number],
      default: 1
    },
    status: {
      type: String,
      default: 'Loading...'
    }
  },
  data() {
    return {}
  },
  beforeCreate() {},
  created() {},
  methods: {
    toItemDetail(item) {
      this.$router.push(
        '/product/detail/' + item.item_id + '/' + this.selectedCatId
      )

      // 暫存商品列表搜尋紀錄
      this.$emit('save-search-history')
    }
  }
}
</script>

<style scoped lang="scss">
@import 'assets/scss/common.scss';
.prodlist-area {
  padding: 0;
  h2 {
    font-size: 1rem;
    color: $font-color;
    margin: 0 0 1.15rem;
    padding: 0;
    text-align: left;
  }
  .card-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .card-items {
    flex: 0 0 calc(50% - 10px);
    margin-bottom: 1.5rem;
    margin-right: 5px;
    margin-left: 5px;
    padding-bottom: 0.65rem;
    background: $white-color;
    border-radius: 0 0 4px 4px;
    -webkit-box-shadow: 1px 1px 3px -1px #999;
    box-shadow: 1px 1px 3px -1px #999;
    .img {
      margin-bottom: 0.5rem;
      background-size: cover !important;
      display: block;
      max-width: 100%;
      height: 0;
      overflow: hidden;
      padding-bottom: 66%;
      border-radius: 4px 4px 0 0;
      position: relative;
      // box-shadow: 0px 1px 5px 0px rgba($black-color, $alpha: .20);
    }
    .img.a {
      background: url(~@/assets/images/6.jpg) no-repeat center;
    }
    .img.b {
      background: url(~@/assets/images/2.jpg) no-repeat center;
    }
    .img.c {
      background: url(~@/assets/images/3.jpg) no-repeat center;
    }
    .img.d {
      background: url(~@/assets/images/4.jpg) no-repeat center;
    }
    .img.e {
      background: url(~@/assets/images/5.jpg) no-repeat center;
    }
    .img.f {
      background: url(~@/assets/images/7.jpg) no-repeat center;
    }
    .img img {
      border-radius: 4px;
    }
    p {
      margin: 0;
      padding: 0 0.65rem;
      line-height: 1.35rem;
    }
    .name {
      font-size: 1rem;
      margin-bottom: 5px;
      color: $font-color;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      height: 2.65rem;
    }
    .price {
      font-size: 1rem;
      color: $price-color;
      font-family: 'Roboto', sans-serif;
      font-weight: bolder !important;
      .sign {
        font-size: 0.5rem;
      }
    }
    .price-o {
      font-size: 0.75rem;
      color: rgba($font-color, $alpha: 0.5);
      font-family: 'Roboto', sans-serif;
      text-decoration: line-through;
    }
    .price-1 {
      font-size: 0.75rem;
      color: rgba($font-color, $alpha: 0.5);
      font-family: 'Roboto', sans-serif;
    }
    .price-2 {
      font-size: 0.75rem;
      color: $price-color;
      font-family: 'Roboto', sans-serif;
    }
  }
}
.overlay {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 66%;
  .promote-sale {
    text-align: right;
    .box {
      transform-origin: right;
      transform: rotate(-5deg);
      color: rgba($white-color, $alpha: 1);
      padding: 0.2rem 0.5rem 0.25rem 0.35rem;
      margin-top: 2px;
      margin-right: -4px;
      background: url(~@/assets/images/img-promote-sale.svg) no-repeat center;
      background-size: contain;
      width: 60px;
      height: 30px;
      float: right;
      big {
        font-size: 1rem;
        font-weight: bolder;
      }
      small {
        font-size: 0.75rem;
        padding-left: 1px;
      }
    }
  }
  .promote-text {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    .triangle-a {
      background: rgba($main-color, $alpha: 1);
      color: #fff;
      height: 30px;
      display: flex;
      align-items: center;
      font-size: 1rem;
      padding: 0 0.25rem 0 0.65rem;
    }
    .triangle-b {
      border-color: transparent transparent transparent
        rgba($main-color, $alpha: 1);
      border-style: solid solid solid solid;
      border-width: 0px 0px 30px 15px;
      height: 0px;
      width: 0px;

      -moz-transform: scaleY(-1);
      -webkit-transform: scaleY(-1);
      -o-transform: scaleY(-1);
      transform: scaleY(-1);
    }
  }
  .promote-text img {
    width: 130px;
  }
}

@media screen and (min-width: 768px) {
  .prodlist-area {
    .card-items {
      flex: 0 0 calc(33.3333% - 10px);
    }
  }
}
</style>
