<template>
  <div class="inter-wrapper">
    <HeaderInter back-path="/" />
    <div class="grey-bg">
      <div class="container-fluid">
        <div class="inter-search">
          <div class="input-group">
            <div class="input-group-prepend">
              <select
                v-model="selectedAreaId"
                name=""
                class="form-control"
                @change="setArea($event)"
              >
                <option
                  v-for="(item, index) in areaList"
                  :key="index"
                  :value="item.area_id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="search-area">
              <input
                v-model="keyword"
                type="text"
                class="searchTerm"
                placeholder="hi~你想去哪裡?"
                @keyup.enter="setKeyword()"
              >
              <button type="submit" class="searchButton" @click="setKeyword()">
                <i class="fa fa-search" />
              </button>
            </div>
          </div>
        </div>
        <InterNav @setCate="setCate" />
        <ProductList
          v-if="productItems"
          :items="productItems"
          :selected-cat-id="selectedCatId"
          :status="status"
          @save-search-history="saveSearchHistory"
        />
        <scroll-loader
          :loader-method="getItems"
          :loader-disable="!enableAutoLoad"
        />
      </div>
    </div>
    <QuickIcon />
  </div>
</template>

<script>
import QuickIcon from '@/components/QuickIcon'
import InterNav from '@/components/InterNav'
import ProductList from '@/components/ProductList'
import { getItemList, getArea } from '@/api/shop'
import ScrollLoader from 'vue-scroll-loader/src/components/Scroll-Loader'
import HeaderInter from '@/components/HeaderInter'

export default {
  name: 'ProductIndex',
  components: {
    HeaderInter,
    ProductList,
    ScrollLoader,
    InterNav,
    QuickIcon
  },
  data() {
    return {
      keyword: this.$route.params.keyword,
      area: this.$route.params.area,
      cate: this.$route.params.cate,
      selectedCatId: this.$route.params.cate
        ? parseInt(this.$route.params.cate)
        : 0,
      selectedAreaId: this.$route.params.area ? this.$route.params.area : 0,
      status: 'Loading...',
      productItems: [],
      areaList: [],
      tagsList: [],
      sortBy: 'buyitems-desc',
      page: 1,
      enableAutoLoad: false,
      swiperOption: {
        slidesPerView: 4.5,
        spaceBetween: 6,
        freeMode: true
      }
    }
  },
  created() {
    getArea().then(response => {
      this.areaList = [
        {
          name: '全部',
          area_id: 0
        }
      ]
      this.areaList = [...this.areaList, ...response.data.data]
    })

    // 若有暫存搜尋紀錄 => 復原搜尋紀錄
    const searchRecord = this.$store.getters.getSearchRecord
    if (searchRecord.data.length) {
      this.productItems = searchRecord.data
      this.page = searchRecord.page
      this.keyword = searchRecord.keyword
      this.selectedCatId = searchRecord.cata_id
      this.selectedAreaId = searchRecord.area_id
      this.enableAutoLoad = searchRecord.enableAutoLoad
      // 滾動到上次的位置
      this.$nextTick(async() => {
        window.scrollTo({
          top: searchRecord.scrollY,
          behavior: 'instant'
        })

        await this.$store.dispatch('clearSearchRecord') // 刪除暫存搜尋紀錄
      })
    } else {
      this.getItems()
    }
  },
  destroyed() {
    this.enableAutoLoad = false
  },
  mounted() {},
  methods: {
    getItems() {
      this.status = 'Loading...'
      const data = {
        page: this.page++,
        paginate: process.env.VUE_APP_PAGINATE_SIZE,
        sortby: this.sortBy
      }
      if (parseInt(this.selectedCatId) !== 0) {
        data.cata_id = this.selectedCatId
      }
      if (parseInt(this.selectedAreaId) !== 0) {
        data.area_id = this.selectedAreaId
      }
      if (this.keyword && this.keyword.replace('/', '').trim() !== '') {
        this.keyword = this.keyword.replace('/', '').trim()
        data.keyword = this.keyword
      }
      this.enableAutoLoad = false
      getItemList(data).then(response => {
        this.productItems = this.productItems ? this.productItems : []
        if (response.data.data) {
          // 調整讀取判斷 因為多了total_paged 可以參考(原本判斷數量是否大於PAGESIZE，但API本身還會濾掉所以修改)
          if (Array.isArray(response.data.data) && !response.data.data.length) {
            this.enableAutoLoad = false
          } else {
            this.productItems = [...this.productItems, ...response.data.data.data]
            this.enableAutoLoad = this.page <= response.data.data.total_page
          }
        }
        this.status = this.productItems.length === 0 ? '敬請期待......' : ''
      })
    },
    setCate(cata_id) {
      this.enableAutoLoad = true
      this.selectedCatId = cata_id
      this.page = 1
      this.productItems = false
      this.$router.push(`/product/cate/${cata_id}`)
      // 直接去當前頁面 所以不用在GET一次商品資料
      // this.getItems()
    },
    setArea(event) {
      this.enableAutoLoad = true
      this.selectedAreaId = event.target.value
      this.page = 1
      this.productItems = false
      this.getItems()
    },
    setKeyword() {
      this.keyword = this.keyword.replace('/', '').trim()
      this.enableAutoLoad = true
      this.page = 1
      this.productItems = false
      this.getItems()
    },
    toItemDetail(item) {
      this.$router.push('/product/detail/' + item.item_id)
    },
    saveSearchHistory() {
      try {
        // 暫存商品列表搜尋紀錄
        this.$store.commit('setSearchRecord', {
          data: this.productItems,
          page: this.page,
          keyword: this.keyword,
          cata_id: this.selectedCatId,
          area_id: this.selectedAreaId,
          scrollY: window.scrollY,
          enableAutoLoad: this.enableAutoLoad
        })
      } catch (error) {
        // 避免 localstorage 儲存超過
        (async() => {
          await this.$store.dispatch('clearSearchRecord')
        })()
      }
    },
    setTag(tag_id) {
      this.enableAutoLoad = true
      this.tag = tag_id
      this.page = 1
      this.productItems = false
      this.getItems()
    }
  }
}
</script>
<style scoped lang="scss">
@import 'assets/scss/common.scss';
.grey-bg > .container-fluid {
  padding-top: 5rem;
}
.inter-search {
  margin: 0;
  padding: 0;
  border: 1px solid rgba($main-color, $alpha: 0.25);
  border-radius: 50px;
  .input-group {
    position: relative;
    width: 100%;
    display: flex;
  }
  .form-control {
    display: block;
    min-width: 120px;
    width: 100%;
    height: 36px;
    padding: 0.5rem 1.5rem 0.5rem;
    font-weight: 400;
    text-align: center;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid rgba($main-color, $alpha: 1);
    border-radius: 3rem 0rem 0rem 3rem;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
  }
  select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(~@/assets/images/arrow-down.svg) no-repeat calc(100% - 15px)
      center;
    background-size: 10px;
    border: none;
    background-color: rgba($main-color, $alpha: 1);
    color: rgba($white-color, $alpha: 1);
  }
}

.search-area {
  flex: 0 0 calc(100% - 130px);
  .searchTerm {
    width: 100%;
    height: 36px;
    padding: 15px 25px 15px 25px;
    border-radius: 0 50px 50px 0;
    outline: none;
    margin: auto;
    border: 0;
    background: rgba($white-color, $alpha: 1);
  }
  .searchTerm:focus {
    color: $sec-color;
  }
  .searchButton {
    width: 36px;
    height: 36px;
    background: $main-color;
    text-align: center;
    color: $white-color;
    border-radius: 50px 50px;
    cursor: pointer;
    position: absolute;
    right: 0;
    outline: none;
    margin: auto;
    border: none;
  }
  .searchButton i {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
